import { useState } from "react";
import styled from "styled-components";
import { Spacer, Text } from "@lrnz/styled";
import { useTranslation } from "react-i18next";
import RevealElement from "./RevealElement";
import { AnimatePresence } from "framer-motion";
import Link from "./Link";

const StyledWrapper = styled.div`
  background: white;
  padding: 20px;
  position: fixed;
  bottom: ${(props) => props.theme.space.sm}px;
  max-width: 460px;
  left: ${(props) => props.theme.space.sm}px;
  right: ${(props) => props.theme.space.sm}px;
  margin: auto;
  color: black;
  z-index: ${(props) => props.theme.zIndex.cookieBanner};
  text-align: center;
  border: 2px solid currentColor;
`;

const StyledItems = styled.div`
  display: flex;
  justify-content: center;

  > * {
    padding: 0 10px;
  }
`;

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const COOKIE_NAME = "cookie-consent-fariasviolins.com";

const CookieBanner = () => {
  const { t } = useTranslation();
  const [isCookieVisible, setIsCookieVisible] = useState(
    !getCookie(COOKIE_NAME)
  );

  const onAccept = (e) => {
    e.preventDefault();
    setCookie(COOKIE_NAME, 1);
    setIsCookieVisible(false);
  };

  const onDecline = () => setIsCookieVisible(false);

  return (
    <AnimatePresence>
      {isCookieVisible && (
        <StyledWrapper
          as={Spacer}
          delay={1}
          forwardedAs={RevealElement}
          top="xs"
          enter="clipYReverse"
          exit="clipY"
        >
          <Text
            variant="small"
            dangerouslySetInnerHTML={{ __html: t("cookie") }}
          />
          <StyledItems>
            <Link onClick={onDecline}>{t("common.decline")}</Link>
            <Link onClick={onAccept}>{t("common.accept")}</Link>
          </StyledItems>
        </StyledWrapper>
      )}
    </AnimatePresence>
  );
};

export default CookieBanner;
