const theme = {
  mediaquery: {
    xl: "@media screen and (min-width: 1439.98px)",
    lg: "@media screen and (max-width: 1439.98px)",
    md: "@media screen and (max-width: 1279.98px)",
    sm: "@media screen and (max-width: 991.98px)",
    xs: "@media screen and (max-width: 575.98px)",
  },
  typography: {
    small: {
      fontSize: "0.75rem",
      lineHeight: 1,
      fontFamily: "acidgrotesk-medium",
    },
    caption: {
      fontSize: "1.125rem",
      lineHeight: 1.23,
      fontFamily: "acidgrotesk-medium",
    },
    paragraph: {
      fontSize: `clamp(18px, 2vw, 2.375rem)`,
      lineHeight: 1.23,
      fontFamily: "acidgrotesk-medium",
    },
    display1: {
      fontSize: `max(32vw, 195px)`,
      lineHeight: 0.68,
      marginBottom: `-0.09em`,
      fontFamily: "grifinitol-regular",
      i: {
        fontFamily: "grifinitol-lightitalic",
      },
    },
    display2: {
      fontSize: "min(24vw, 13.626rem)",
      marginTop: "0.045em",
      marginBottom: "-0.05em",
      lineHeight: 0.6,
      fontFamily: "grifinitol-regular",
      i: {
        letterSpacing: 2,
        fontFamily: "grifinitol-lightitalic",
      },
    },
    display3: {
      fontSize: "min(16vw, 9.25rem)",
      lineHeight: 0.86,
      fontFamily: "grifinitol-regular",
      i: {
        letterSpacing: 2,
        fontFamily: "grifinitol-lightitalic",
      },
    },
    display4: {
      fontSize: "min(12vw, 5.3rem)",
      lineHeight: 0.8,
      fontFamily: "grifinitol-regular",
      i: {
        letterSpacing: 2,
        fontFamily: "grifinitol-lightitalic",
      },
    },
    interface: {
      fontSize: `1.875rem`,
      lineHeight: 1,
      fontFamily: "ayer-medium",
      i: {
        fontFamily: "ayer-mediumitalic",
      },
    },
  },
  space: {
    xs: 10,
    sm: 20,
    md: 30,
    lg: 60,
    xl: 120,
    xxl: 160,
    xxxl: 240,
    xxxxl: 300,
    detail: "min(22%, 190px)",
  },
  columns: {
    default: 24,
  },
  gutter: {
    default: 10,
  },
  zIndex: {
    menu: 3,
    interface: 4,
    gallery: 2,
    cursor: 5,
    loader: 10,
    cookieBanner: 100,
  },
};

export default theme;
